import { API_ENDPOINTS } from "@/configs";
import { useGet, usePost } from "@/lib";
import { Staff } from "utilities";

export const useLogout = () =>
  usePost({
    url: API_ENDPOINTS.CORE.AUTH.LOGOUT,
  });

export const useGetProfile = () => {
  return useGet<Staff>({
    url: API_ENDPOINTS.CORE.AUTH.PROFILE,
  });
};

import React from "react";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Divider,
  Group,
  InputField,
  Menu,
  OTPInput,
  Text,
  UnstyledButton,
} from "@axazara/raiton-atoms";
import {
  AddOutline,
  ArrowDownOutline,
  SearchNormal1Outline,
  CloseCircleFill,
} from "@axazara/raiton-icons";
import { closeAllModals, closeModal, useForm } from "@axazara/raiton-molecules";
import {
  Pricing,
  PricingPayload,
  ProviderAccount,
  useFilterDropdownStyles,
} from "utilities";
import { showErrorNotification, showSuccessNotification } from "@/utils";
import { classNames, useDebouncedValue } from "@axazara/raiton-utils";
import { useGetProviders, useUpdatePricing } from "../services";
import { useQueryClient } from "@tanstack/react-query";
import { API_ENDPOINTS } from "@/configs";

export function EditPricing({ pricing }: { pricing: Pricing }) {
  const queryClient = useQueryClient();

  const [providerOpened, setProviderOpened] = React.useState(false);

  const [providerAlternativeOpened, setProviderAlternativeOpened] =
    React.useState(false);

  const { classes: providerClasses } = useFilterDropdownStyles({
    opened: providerOpened,
    width: "100%",
  });

  const { classes: providerAlternativeClasses } = useFilterDropdownStyles({
    opened: providerAlternativeOpened,
    width: "100%",
  });

  const [searchProviderQuery, setSearchProviderQuery] = React.useState("");
  const [debouncedProviderSearch] = useDebouncedValue(searchProviderQuery, 500);

  const [providerFilter, setProviderFilter] =
    React.useState<ProviderAccount | null>(pricing?.provider);

  const [providerAlternativeFilter, setProviderAlternativeFilter] =
    React.useState<ProviderAccount | null>(pricing?.alt_provider);

  const { data: providers } = useGetProviders({
    search: debouncedProviderSearch,
  });

  const pricingDataForm = useForm<PricingPayload>({
    initialValues: {
      provider_id: pricing.provider.id,
      alternative_provider_id: pricing?.alt_provider?.id,
      price: pricing?.price,
      sale_price: pricing?.sale_price,
      is_high_available: pricing.is_high_available,
      otp_code: "",
    },
  });

  const useUpdatePricingMutation = useUpdatePricing({ pricingId: pricing.id });

  async function handleSubmit(values: PricingPayload) {
    try {
      if (
        values.provider_id !== pricing.provider.id ||
        values.alternative_provider_id !== pricing?.alt_provider?.id ||
        values.price !== pricing?.price ||
        values.sale_price !== pricing?.sale_price ||
        values.is_high_available !== pricing.is_high_available
      ) {
        const response = await useUpdatePricingMutation.mutateAsync(values);
        queryClient.invalidateQueries({
          queryKey: [API_ENDPOINTS.PRICING.BY_ID],
        });
        closeModal("");

        showSuccessNotification((response as any)?.message);
      }
    } catch (error) {
      showErrorNotification((error as any)?.message);
    }
  }

  const providerItems = providers?.data?.map((item) => (
    <Menu.Item
      className={providerFilter?.name === item?.name ? "bg-neutral-90" : ""}
      icon={<Avatar size={24} src={item?.provider?.icon_url} />}
      onClick={() => {
        pricingDataForm.setFieldValue("provider_id", item.id);
        setProviderFilter(item);
      }}
      key={item?.id}
    >
      <Box className="flex flex-col space-y-1">
        <span className="text-p-01-medium">{item?.name}</span>
        <span className="text-footer">{item?.provider?.name}</span>
      </Box>
    </Menu.Item>
  ));

  const providerAlternativeItems = providers?.data?.map((item) => (
    <Menu.Item
      className={
        providerAlternativeFilter?.name === item?.name ? "bg-neutral-90" : ""
      }
      icon={<Avatar size={24} src={item?.provider?.icon_url} />}
      onClick={() => {
        pricingDataForm.setFieldValue("alternative_provider_id", item.id);
        setProviderAlternativeFilter(item);
      }}
      key={item?.id}
    >
      <Box className="flex flex-col space-y-1">
        <span className="text-p-01-medium">{item?.name}</span>
        <span className="text-footer">{item?.provider?.name}</span>
      </Box>
    </Menu.Item>
  ));

  return (
    <Box className="mt-12">
      <Box className="flex items-center space-x-36">
        <Box className="flex flex-col space-y-2">
          <Text className=" text-p-01 text-neutral-50">Service</Text>
          <Box className="flex items-center space-x-2.5">
            <Avatar src={pricing.service.icon_url} size={32} />
            <Text className=" text-p-01-medium text-neutral-40">
              {pricing.service.name}
            </Text>
          </Box>
        </Box>

        <Box className="flex flex-col space-y-2">
          <Text className=" text-p-01 text-neutral-50">Country</Text>
          <Box className="flex items-center space-x-2.5">
            <Avatar src={pricing.country.flag} size={32} />
            <Text className=" text-p-01-medium text-neutral-40">
              {pricing.country.name}
            </Text>
          </Box>
        </Box>
      </Box>

      <Box
        component="form"
        onSubmit={pricingDataForm.onSubmit((values) => handleSubmit(values))}
      >
        <Box className="w-full mt-6 mb-4">
          <Text className=" text-p-01 text-neutral-50 mb-3">Provider</Text>
          <Menu
            onOpen={() => setProviderOpened(true)}
            onClose={() => setProviderOpened(false)}
            radius="md"
            withinPortal
          >
            <Menu.Target>
              <UnstyledButton
                className={`${providerClasses.control} space-x-4`}
              >
                {providerOpened ? (
                  <InputField
                    onClick={(e) => e.stopPropagation()}
                    classNames={{
                      input:
                        "rounded-none !pl-5 !border-0 focus:shadow-none !outline-none focus:border-0 focus-within:border-0 focus-within:border-transparent focus:border-transparent focus:appearance-none  h-[38px] min-h-[38px]",
                    }}
                    value={searchProviderQuery}
                    iconWidth={12}
                    size="xs"
                    onChange={(e) => setSearchProviderQuery(e.target.value)}
                    icon={<SearchNormal1Outline className="w-3 h-3" />}
                  />
                ) : providerFilter ? (
                  <>
                    <Group spacing="xs" className="w-full">
                      {providerFilter?.provider?.icon_url && (
                        <Avatar
                          size={24}
                          src={providerFilter?.provider?.icon_url}
                        />
                      )}
                      <span className={`${providerClasses.label}`}>
                        {providerFilter?.name}
                      </span>
                    </Group>
                    {providerFilter && (
                      <CloseCircleFill
                        className="w-4 h-4 text-neutral-50 mr-2"
                        onClick={() => {
                          setProviderFilter(null);
                        }}
                      />
                    )}
                  </>
                ) : (
                  <Text
                    className="text-footer"
                    styles={() => ({ root: { fontSize: 12 } })}
                  >
                    Search provider
                  </Text>
                )}
                <ArrowDownOutline
                  className={classNames(
                    providerClasses.icon,
                    "w-4 h-4 text-neutral-40",
                  )}
                />
              </UnstyledButton>
            </Menu.Target>
            <Menu.Dropdown>{providerItems}</Menu.Dropdown>
          </Menu>
        </Box>
        <Box className="w-full mt-6 mb-4">
          <Text className=" text-p-01 text-neutral-50 mb-3">
            Provider alternative
          </Text>
          <Menu
            onOpen={() => setProviderAlternativeOpened(true)}
            onClose={() => setProviderAlternativeOpened(false)}
            radius="md"
            withinPortal
          >
            <Menu.Target>
              <UnstyledButton
                className={`${providerAlternativeClasses.control} space-x-4`}
              >
                {providerAlternativeOpened ? (
                  <InputField
                    onClick={(e) => e.stopPropagation()}
                    classNames={{
                      input:
                        "rounded-none !pl-5 !border-0 focus:shadow-none !outline-none focus:border-0 focus-within:border-0 focus-within:border-transparent focus:border-transparent focus:appearance-none  h-[38px] min-h-[38px]",
                    }}
                    value={searchProviderQuery}
                    iconWidth={12}
                    size="xs"
                    onChange={(e) => setSearchProviderQuery(e.target.value)}
                    icon={<SearchNormal1Outline className="w-3 h-3" />}
                  />
                ) : providerAlternativeFilter ? (
                  <>
                    <Group spacing="xs" className="w-full">
                      {providerAlternativeFilter?.provider?.icon_url && (
                        <Avatar
                          size={24}
                          src={providerAlternativeFilter?.provider?.icon_url}
                        />
                      )}
                      <span className={`${providerAlternativeClasses.label}`}>
                        {providerAlternativeFilter?.name}
                      </span>
                    </Group>
                    {providerAlternativeFilter && (
                      <CloseCircleFill
                        className="w-4 h-4 text-neutral-50 mr-2"
                        onClick={() => {
                          setProviderAlternativeFilter(null);
                        }}
                      />
                    )}
                  </>
                ) : (
                  <Text
                    className="text-footer"
                    styles={() => ({ root: { fontSize: 12 } })}
                  >
                    Search provider alternative
                  </Text>
                )}
                <ArrowDownOutline
                  className={classNames(
                    providerClasses.icon,
                    "w-4 h-4 text-neutral-40",
                  )}
                />
              </UnstyledButton>
            </Menu.Target>
            <Menu.Dropdown>{providerAlternativeItems}</Menu.Dropdown>
          </Menu>
        </Box>

        <InputField
          {...pricingDataForm.getInputProps("price")}
          label="Price"
          classNames={{
            input: "rounded-xl",
            label: "mb-3 text-p-01-semibold text-dark",
          }}
          radius={12}
          placeholder="Price"
          size="xs"
          className="w-full mb-4"
        />

        <InputField
          {...pricingDataForm.getInputProps("sale_price")}
          label="sale price"
          classNames={{
            input: "rounded-xl",
            label: "mb-3 text-p-01-semibold text-dark",
          }}
          radius={12}
          placeholder="sale price"
          size="xs"
          className="w-full mb-4"
        />

        <Box className="flex items-center justify-between mb-6">
          <Text className="text-neutral-20 text-p-01-semibold">
            High available
          </Text>
          <Checkbox
            {...pricingDataForm.getInputProps("is_high_available")}
            size="lg"
            checked={pricingDataForm.values.is_high_available}
            icon={({ indeterminate, className }) =>
              indeterminate ? (
                <Box
                  className={className}
                  style={{ transform: "rotate(45deg)" }}
                >
                  <AddOutline className="text-neutral-30 w-6 h-6" />
                </Box>
              ) : (
                <Box
                  className={`${className} flex items-center justify-center`}
                >
                  <img src="/assets/images/mark-white.svg" alt="mark_icons" />
                </Box>
              )
            }
          />
        </Box>
        <Divider color="neutral.8" className="w-full mb-6" />

        <Box className="flex flex-col mb-8">
          <Text className="text-center text-neutral-30 text-p-01 mb-4">
            "Please enter the confirmation code you see on the authentication
            application"
          </Text>
          <Box className="flex justify-center items-center">
            <OTPInput
              radius={12}
              size="md"
              required
              onChange={(res) => {
                pricingDataForm.setFieldValue("otp_code", res);
              }}
              {...pricingDataForm.getInputProps("otp_code")}
              allowedCharacters="numeric"
            />
          </Box>
        </Box>

        <Box className="flex space-x-2">
          <Button
            onClick={() => closeAllModals()}
            variant="outline"
            color="error.4"
            className="text-p-01-semibold"
          >
            Cancel
          </Button>
          <Button
            fullWidth
            type="submit"
            className="text-p-01-semibold"
            disabled={!pricingDataForm.isDirty()}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

import React from "react";
import {
  Box,
  InputField,
  Button,
  Text,
  Divider,
  OTPInput,
  Avatar,
  DatePickerInput,
} from "@axazara/raiton-atoms";
import { Calendar2Outline, ArrowDownOutline } from "@axazara/raiton-icons";
import { Discount } from "utilities";
import { closeModal, useForm } from "@axazara/raiton-molecules";
import { showErrorNotification, showSuccessNotification } from "@/utils";
import { DateRange } from "@/types";
import { useUpdateDiscountCode } from "../services";
import { Link } from "react-router-dom";
import { API_ENDPOINTS } from "@/configs";
import { useQueryClient } from "@tanstack/react-query";

export function EditDiscountCodeModal({ discount }: { discount: Discount }) {
  const queryClient = useQueryClient();
  const [selectedDateRange, setSelectedDateRange] = React.useState<DateRange>([
    null,
    null,
  ]);

  const discountCodeForm = useForm<Partial<Discount>>({
    initialValues: {
      use_limit: discount?.use_limit,
      otp_code: "",
    },
  });
  const useUpdateDiscountCodeMutation = useUpdateDiscountCode({
    discountId: discount?.id,
  });

  async function handleSubmit(values: Partial<Discount>) {
    try {
      if (values.use_limit !== discount?.use_limit) {
        const response =
          await useUpdateDiscountCodeMutation.mutateAsync(values);
        queryClient.invalidateQueries({
          queryKey: [API_ENDPOINTS.DISCOUNTS.ROOT],
        });

        showSuccessNotification((response as any).message);
        closeModal("");
      }
    } catch (error) {
      showErrorNotification((error as any)?.message);
    }
  }

  React.useEffect(() => {
    if (discount) {
      const dateRange: DateRange = [
        new Date(discount?.valid_from),
        new Date(discount?.expires_at),
      ];
      const updateDate = (_prevState: DateRange) => {
        return dateRange;
      };
      setSelectedDateRange(updateDate);
    }
  }, []);

  return (
    <Box
      component="form"
      onSubmit={discountCodeForm.onSubmit((values) => handleSubmit(values))}
      className="space-y-6"
    >
      <Box className="space-y-2">
        <Box className="flex justify-between">
          <Text className="text-p-01-semibold">Reduction code</Text>
          <Text className="text-p-01">{discount?.code}</Text>
        </Box>
        <Box className="flex justify-between">
          <Text className="text-p-01-semibold">Bundle</Text>
          <Text className="text-p-01">{discount?.bundle?.coins}</Text>
        </Box>
        <Box className="flex justify-between">
          <Text className="text-p-01-semibold">Type</Text>
          <Text className="text-p-01">{discount?.type}</Text>
        </Box>
      </Box>
      <Text className="text-p-02-semibold">Added by</Text>
      <Box className="flex justify-between">
        <Box className="flex items-center gap-4">
          <Avatar src={discount?.added_by?.profile_photo_url} size={48} />
          <Box>
            <Text className="text-p-01-medium">
              {discount?.added_by?.last_name} {discount?.added_by?.first_name}
            </Text>
            <Text className="text-caption-medium text-neutral-60">
              {discount.added_by?.email}
            </Text>
          </Box>
        </Box>
        <Button
          size="xs"
          variant="outline"
          component={Link}
          to={`/staffs/${discount?.added_by?.id}`}
        >
          View
        </Button>
      </Box>
      <Divider color="neutral.9" className="border-neutral-90" />
      <InputField
        label="Maximal use"
        placeholder="Maximal use"
        size="xs"
        {...discountCodeForm.getInputProps("use_limit")}
      />
      <DatePickerInput
        size="xs"
        type="range"
        clearable
        icon={<Calendar2Outline className="w-4 h-4 text-neutral-40" />}
        value={selectedDateRange}
        numberOfColumns={2}
        styles={(theme) => ({
          root: {
            "> div": {
              borderColor: theme.colors.neutral[8],
            },
          },
        })}
        onChange={(value) => {
          setSelectedDateRange(value);
        }}
        allowSingleDateSelectionInRange
        rightSection={
          selectedDateRange[1] ? null : (
            <ArrowDownOutline className="w-4 h-4 text-neutral-40" />
          )
        }
      />
      <Box className="flex flex-col mb-8">
        <Text className="text-center text-neutral-30 text-p-01 mb-4">
          Veuillez entrer le code de confirmation que vous voyez sur
          l’application d’authentification
        </Text>
        <Box className="flex justify-center items-center">
          <OTPInput
            radius={12}
            size="md"
            required
            onChange={(res) => {
              discountCodeForm.setFieldValue("otp_code", res);
            }}
            allowedCharacters="numeric"
          />
        </Box>
      </Box>
      <Box>
        <Button
          type="submit"
          disabled={!discountCodeForm.isDirty()}
          loading={useUpdateDiscountCodeMutation.isPending}
          size="xs"
          fullWidth
        >
          Update discount
        </Button>
      </Box>
    </Box>
  );
}

import { API_ENDPOINTS } from "@/configs";
import { useGet, usePost, usePut } from "@/lib";
import { buildFetchParams } from "@axazara/raiton-utils";
import { formatPath } from "@axazara/shuriken";
import {
  Bundle,
  Currencies,
  DeleteBundleResponse,
  PaginatedBundles,
} from "utilities";

export const useGetBundles = ({
  page,
  search,
  currency,
  status,
  initialData,
}: {
  page?: number;
  search?: string;
  currency?: string;
  status?: boolean;
  initialData?: PaginatedBundles;
}) => {
  const params = buildFetchParams({
    page,
    search,
    currency,
    status,
  });

  return useGet<PaginatedBundles>({
    url: API_ENDPOINTS.BUNDLES.ROOT,
    params,
    queryOptions: {
      initialData,
    },
  });
};

export const useUpdateBundle = ({ bundleId }: { bundleId: string }) => {
  return usePut<Partial<Bundle>, Bundle>({
    url: formatPath(API_ENDPOINTS.BUNDLES.BY_ID, { bundleId }),
  });
};

export const useAddBundle = () => {
  return usePost<Partial<Bundle>, Bundle>({
    url: API_ENDPOINTS.BUNDLES.ROOT,
  });
};

export const useDeleteBundle = (bundleId: string) => {
  return usePost<{ otp_code: string }, DeleteBundleResponse>({
    url: formatPath(API_ENDPOINTS.BUNDLES.DELETE_BUNDLE, { bundleId }),
  });
};

export const useUpdateBundleVisibility = ({
  bundleId,
}: {
  bundleId: string;
}) => {
  return usePut<Partial<Bundle>, Bundle>({
    url: formatPath(API_ENDPOINTS.BUNDLES.BY_ID, { bundleId }),
  });
};
export const useGetCurrencies = ({
  page,
  search,
}: {
  page?: number;
  search?: string;
}) => {
  const params = buildFetchParams({
    page,
    search,
  });

  return useGet<Currencies>({
    url: API_ENDPOINTS.UTILS.CURRENCIES,
    params,
  });
};

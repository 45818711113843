import {
  Discount,
  DiscountCodePayload,
  DiscountCodeResponse,
  PaginatedBundles,
  PaginatedDiscounts,
  PaginatedUsers,
} from "utilities";
import { buildFetchParams } from "@axazara/raiton-utils";
import { useGet, usePost, usePut } from "@/lib";
import { API_ENDPOINTS } from "@/configs";
import { formatPath } from "@axazara/shuriken";

export const useGetDiscountsCode = ({
  page,
  search,
  status,
  initialData,
}: {
  page?: number;
  search?: string;
  initialData?: PaginatedDiscounts;
  status?: boolean;
}) => {
  const params = buildFetchParams({
    page,
    search,
    status,
  });

  return useGet<PaginatedDiscounts>({
    url: API_ENDPOINTS.DISCOUNTS.ROOT,
    params,
    queryOptions: {
      initialData,
    },
  });
};

export const useAddDiscountCode = () => {
  return usePost<DiscountCodePayload, DiscountCodeResponse>({
    url: API_ENDPOINTS.DISCOUNTS.ROOT,
  });
};

export const useUpdateDiscountCode = ({
  discountId,
}: {
  discountId: string;
}) => {
  return usePut<Partial<Discount>, Discount>({
    url: formatPath(API_ENDPOINTS.DISCOUNTS.BY_ID, { discountId }),
  });
};

export const useDeleteDiscountCode = ({
  discountId,
}: {
  discountId: string;
}) => {
  return usePost<{ otp_code: string }, DiscountCodeResponse>({
    url: formatPath(API_ENDPOINTS.DISCOUNTS.DELETE_DISCOUNT, { discountId }),
  });
};

export const useUpdateDiscountCodeVisibility = ({
  discountId,
}: {
  discountId: string;
}) => {
  return usePut<Partial<Discount>, Discount>({
    url: formatPath(API_ENDPOINTS.DISCOUNTS.BY_ID, { discountId }),
  });
};
export const useGetUsers = ({
  page,
  search,
}: {
  page?: number;
  search?: string;
}) => {
  const params = buildFetchParams({
    page,
    search,
  });
  return useGet<PaginatedUsers>({
    url: API_ENDPOINTS.USERS.ROOT,
    params,
  });
};

export const useGetBundle = ({
  page,
  search,
}: {
  page?: number;
  search?: string;
}) => {
  const params = buildFetchParams({
    page,
    search,
  });
  return useGet<PaginatedBundles>({
    url: API_ENDPOINTS.BUNDLES.ROOT,
    params,
  });
};

import { API_ENDPOINTS } from "@/configs";
import { useGet } from "@/lib";
import { DateRange } from "@/types";
import { buildFetchParams } from "@axazara/raiton-utils";
import { formatPath } from "@axazara/shuriken";
import {
  Countries,
  PaginatedOrders,
  PaginatedRefills,
  PaginatedTransactions,
  PaginatedUsers,
  Services,
  Summary,
  User,
  formatDate,
} from "utilities";

export const useGetUsers = ({
  page,
  search,
  initialData,
}: {
  page?: number;
  search?: string;
  initialData?: PaginatedUsers;
} = {}) => {
  const params = buildFetchParams({
    page,
    search,
  });

  return useGet<PaginatedUsers>({
    url: API_ENDPOINTS.USERS.ROOT,
    params,
    queryOptions: {
      initialData,
    },
  });
};

export const useGetUser = (userId: string) => {
  return useGet<User>({
    url: formatPath(API_ENDPOINTS.USERS.BY_ID, { userId }),
  });
};

export const useGetUserOrders = (
  userId: string,
  {
    page = 1,
    search,
    initialData,
    dateRange = [null, null],
    status = "",
    country = "",
    service = "",
    provider_id = "",
  }: {
    page?: number;
    search?: string;
    initialData?: PaginatedOrders;
    dateRange?: DateRange;
    status?: string;
    service?: string;
    country?: string;
    provider_id?: string;
  },
) => {
  const [fromDate, toDate] = dateRange;

  const params = buildFetchParams({
    from:
      fromDate && formatDate(fromDate.toDateString(), undefined, "YYYY-MM-DD"),
    to: toDate && formatDate(toDate.toDateString(), undefined, "YYYY-MM-DD"),
    page,
    search,
    status,
    country,
    service,
    provider_id,
  });

  const url = formatPath(API_ENDPOINTS.USERS.USER.ORDERS, { userId });

  return useGet<PaginatedOrders>({
    url,
    params,
    queryOptions: {
      initialData,
    },
  });
};

export const useGetUserServices = ({
  page,
  search,
}: {
  page?: number;
  search?: string;
}) => {
  const params = buildFetchParams({
    page,
    search,
  });

  return useGet<Services>({
    url: API_ENDPOINTS.UTILS.SERVICES,
    params,
  });
};

export const useGetUserCountries = ({
  page,
  search,
}: {
  page?: number;
  search?: string;
}) => {
  const params = buildFetchParams({
    page,
    search,
  });

  return useGet<Countries>({
    url: API_ENDPOINTS.UTILS.COUNTRIES,
    params,
  });
};

export const useGetUserTransactions = (
  userId: string,
  {
    page,
    search,
    initialData,
    type = "",
    dateRange = [null, null],
  }: {
    page?: number;
    search?: string;
    type?: string;
    initialData?: PaginatedTransactions;
    dateRange?: DateRange;
  },
) => {
  const [fromDate, toDate] = dateRange;

  const params = buildFetchParams({
    from:
      fromDate && formatDate(fromDate.toDateString(), undefined, "YYYY-MM-DD"),
    to: toDate && formatDate(toDate.toDateString(), undefined, "YYYY-MM-DD"),
    page,
    search,
    type,
  });

  const url = formatPath(API_ENDPOINTS.USERS.USER.TRANSACTIONS, { userId });

  return useGet<PaginatedTransactions>({
    url,
    params,
    queryOptions: {
      initialData,
    },
  });
};

export const useGetSummary = (userId: string) => {
  return useGet<Summary>({
    url: formatPath(API_ENDPOINTS.USERS.USER.SUMMARY, { userId }),
  });
};

export const useGetUserRefills = (
  userId: string,
  {
    page,
    search,
    status = "",
    initialData,
    dateRange = [null, null],
  }: {
    page?: number;
    search?: string;
    initialData?: PaginatedRefills;
    status?: string;
    dateRange?: DateRange;
  },
) => {
  const [fromDate, toDate] = dateRange;

  const params = buildFetchParams({
    from:
      fromDate && formatDate(fromDate.toDateString(), undefined, "YYYY-MM-DD"),
    to: toDate && formatDate(toDate.toDateString(), undefined, "YYYY-MM-DD"),
    page,
    search,
    status,
  });

  const url = formatPath(API_ENDPOINTS.USERS.USER.REFILLS, { userId });

  return useGet<PaginatedRefills>({
    url,
    params,
    queryOptions: {
      initialData,
    },
  });
};

import { Box, InputField, LoadingOverlay } from "@axazara/raiton-atoms";
import {
  SearchNormal1Outline,
  CloseCircleFill,
  Profile2userFill,
} from "@axazara/raiton-icons";
import { PaginatedUsers } from "utilities";
import { EmptyState } from "@/components";
import { useGetUsers } from "../../services";
import { PaginationControls } from "@/components/PaginationControls";
import { useSetPageMeta } from "@/hooks";
import { UserTable } from "../../components";
import { useUsers } from "./useUsers";

export function Users() {
  useSetPageMeta({
    title: "Users",
    icon: Profile2userFill,
  });

  const { data: usersData, isLoading } = useGetUsers({ page: 1 });

  if (isLoading) return <LoadingOverlay visible />;

  if (usersData?.data && usersData.data.length > 0)
    return <UsersPageRenderer initialData={usersData} />;

  return <EmptyState title="No user yet" />;
}

function UsersPageRenderer({ initialData }: { initialData: PaginatedUsers }) {
  const {
    isLoading,
    searchQuery,
    setSearchQuery,
    debouncedSearch,
    users,
    page,
    setPage,
  } = useUsers({
    initialData,
  });

  return (
    <Box>
      <LoadingOverlay visible={isLoading} />
      <Box className="pt-4">
        <Box className="flex justify-end w-full sticky top-0 z-10 bg-white py-5">
          <InputField
            placeholder="Search user"
            size="xs"
            icon={<SearchNormal1Outline className="w-5 h-5" />}
            className="w-full"
            onChange={(e) => setSearchQuery(e.target.value)}
            value={searchQuery}
            rightSection={
              debouncedSearch ? (
                <CloseCircleFill
                  className="w-4 h-4 text-neutral-60"
                  onClick={() => {
                    setSearchQuery("");
                  }}
                />
              ) : null
            }
            classNames={{
              input: "rounded-xl",
            }}
          />
        </Box>

        <Box>
          {users?.data && (
            <>
              <UserTable users={users.data} />

              <PaginationControls
                page={page}
                pagination={users?.pagination}
                onPageChange={setPage}
              />
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
}

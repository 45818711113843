import React from "react";
import {
  Box,
  InputField,
  Button,
  Divider,
  Text,
  OTPInput,
  Select,
  Menu,
  Avatar,
  UnstyledButton,
  Group,
  DatePickerInput,
} from "@axazara/raiton-atoms";
import {
  Calendar2Outline,
  ArrowDownOutline,
  CloseCircleFill,
  SearchNormal1Outline,
} from "@axazara/raiton-icons";
import { closeModal, useForm } from "@axazara/raiton-molecules";
import {
  Bundle,
  DiscountCodePayload,
  useFilterDropdownStyles,
  User,
} from "utilities";
import { showErrorNotification, showSuccessNotification } from "@/utils";
import { classNames, useDebouncedValue } from "@axazara/raiton-utils";
import { DateRange } from "@/types";
import { useAddDiscountCode, useGetBundle, useGetUsers } from "../services";
import { useQueryClient } from "@tanstack/react-query";
import { API_ENDPOINTS } from "@/configs";

export function AddDiscountCodeModal() {
  const queryClient = useQueryClient();
  const discountCodeType = [
    { label: "PERCENTAGE", value: "percentage" },
    { label: "FIXED", value: "fixed" },
  ];

  const addDiscountCodeForm = useForm<DiscountCodePayload>({
    initialValues: {
      code: "",
      type: "",
      value: null,
      otp_code: "",
      use_limit: null,
      bundle_id: "",
      user_id: "",
      valid_from: "",
      expires_at: "",
    },
  });

  const useAddDiscountCodeMutation = useAddDiscountCode();

  async function handleAddDiscountCode(values: DiscountCodePayload) {
    try {
      const response = await useAddDiscountCodeMutation.mutateAsync(values);
      queryClient.invalidateQueries({
        queryKey: [API_ENDPOINTS.DISCOUNTS.ROOT],
      });

      showSuccessNotification((response as any)?.message);
      closeModal("");
    } catch (error) {
      showErrorNotification((error as any)?.message);
    }
  }

  const [usersOpened, setUsersOpened] = React.useState(false);
  const [searchUserQuery, setSearchUserQuery] = React.useState("");
  const [debouncedUserSearch] = useDebouncedValue(searchUserQuery, 500);

  const { classes: currencyClasses } = useFilterDropdownStyles({
    opened: usersOpened,
    width: "100%",
  });
  const [userFilter, setUserFilter] = React.useState<User | null>();

  const [bundlesOpened, setBundlesOpened] = React.useState(false);
  const [searchBundleQuery, setSearchBundleQuery] = React.useState("");
  const [debouncedBundleSearch] = useDebouncedValue(searchBundleQuery, 500);

  const { classes: bundleClasses } = useFilterDropdownStyles({
    opened: bundlesOpened,
    width: "100%",
  });
  const [bundleFilter, setBundleFilter] = React.useState<Bundle | null>();

  const [selectedDateRange, setSelectedDateRange] = React.useState<DateRange>([
    null,
    null,
  ]);

  const { data: usersData } = useGetUsers({
    page: 1,
    search: debouncedUserSearch,
  });
  const { data: bundleData } = useGetBundle({
    page: 1,
    search: debouncedBundleSearch,
  });

  const bundleItem = bundleData?.data?.map((bundle) => (
    <Menu.Item
      key={bundle.id}
      className={bundleFilter?.id === bundle.id ? "bg-neutral-90" : ""}
      onClick={() => {
        addDiscountCodeForm.setFieldValue("bundle_id", bundle?.id);
        setBundleFilter(bundle);
      }}
    >
      {bundle?.coins}
    </Menu.Item>
  ));

  const usersItem = usersData?.data?.map((user) => (
    <Menu.Item
      key={user?.id}
      className={userFilter?.id === user.id ? "bg-neutral-90" : ""}
      icon={<Avatar size={24} src={user?.profile_photo_url} />}
      onClick={() => {
        addDiscountCodeForm.setFieldValue("user_id", user?.id);
        setUserFilter(user);
      }}
    >
      {user?.first_name} {user?.last_name}
    </Menu.Item>
  ));

  return (
    <Box
      component="form"
      onSubmit={addDiscountCodeForm.onSubmit(handleAddDiscountCode)}
      className="space-y-6"
    >
      <InputField
        label="Reduction code"
        {...addDiscountCodeForm.getInputProps("code")}
        placeholder="Reduction code here"
        size="xs"
      />
      <Select
        optionResolver={(type: any) => ({
          label: type.label,
          value: type.value,
        })}
        itemComponent={({ label, value, ...others }) => (
          <Box className="py-2" key={value} {...others}>
            <Box className="flex flex-row items-center">
              <Text className="text-p-01-medium">{label}</Text>
            </Box>
          </Box>
        )}
        placeholder="Select type"
        data={discountCodeType as any}
        {...addDiscountCodeForm.getInputProps("type")}
        size="xs"
      />
      <InputField
        placeholder="Value here"
        label="Value"
        min={0}
        max={addDiscountCodeForm.values.type === "fixed" ? undefined : 100}
        type="number"
        {...addDiscountCodeForm.getInputProps("value")}
        size="xs"
      />
      <InputField
        label="Use limit"
        placeholder="Use limit here"
        {...addDiscountCodeForm.getInputProps("use_limit")}
        size="xs"
        type="number"
      />

      <Menu
        onOpen={() => setUsersOpened(true)}
        onClose={() => setUsersOpened(false)}
        radius="md"
        width="target"
        withinPortal
      >
        <Menu.Target>
          <UnstyledButton className={`${currencyClasses.control} space-x-4`}>
            {usersOpened ? (
              <InputField
                onClick={(e) => e.stopPropagation()}
                styles={() => ({})}
                classNames={{
                  input:
                    "rounded-none !pl-5 !border-0 focus:shadow-none !outline-none focus:border-0 focus-within:border-0 focus-within:border-transparent focus:border-transparent focus:appearance-none  h-[38px] min-h-[38px]",
                }}
                value={searchUserQuery}
                className="w-full"
                iconWidth={12}
                size="xs"
                onChange={(e) => setSearchUserQuery(e.target.value)}
                icon={<SearchNormal1Outline className="w-3 h-3" />}
              />
            ) : userFilter ? (
              <>
                <Group spacing="xs" className="w-full">
                  {userFilter?.profile_photo_url && (
                    <Avatar size={24} src={userFilter?.profile_photo_url} />
                  )}
                  <span
                    className={`${currencyClasses.label} text-ellipsis overflow-hidden whitespace-nowrap w-16`}
                  >
                    {userFilter?.first_name} {userFilter?.last_name}
                  </span>
                </Group>
                {userFilter && (
                  <CloseCircleFill
                    className="w-4 h-4 text-neutral-50 mr-2"
                    onClick={() => {
                      setUserFilter(null);
                    }}
                  />
                )}
              </>
            ) : (
              <Text
                className="text-footer"
                styles={() => ({ root: { fontSize: 12 } })}
              >
                Select user
              </Text>
            )}
            <ArrowDownOutline
              className={classNames(
                currencyClasses.icon,
                "w-4 h-4 text-neutral-40",
              )}
            />
          </UnstyledButton>
        </Menu.Target>
        <Menu.Dropdown className="max-h-80 overflow-y-auto">
          {usersItem}
        </Menu.Dropdown>
      </Menu>
      <Menu
        onOpen={() => setBundlesOpened(true)}
        onClose={() => setBundlesOpened(false)}
        radius="md"
        width="target"
        withinPortal
      >
        <Menu.Target>
          <UnstyledButton className={`${bundleClasses.control} space-x-4`}>
            {bundlesOpened ? (
              <InputField
                onClick={(e) => e.stopPropagation()}
                styles={() => ({})}
                classNames={{
                  input:
                    "rounded-none !pl-5 !border-0 focus:shadow-none !outline-none focus:border-0 focus-within:border-0 focus-within:border-transparent focus:border-transparent focus:appearance-none  h-[38px] min-h-[38px]",
                }}
                value={searchBundleQuery}
                className="w-full"
                iconWidth={12}
                size="xs"
                onChange={(e) => setSearchBundleQuery(e.target.value)}
                icon={<SearchNormal1Outline className="w-3 h-3" />}
              />
            ) : bundleFilter ? (
              <>
                <Group spacing="xs" className="w-full">
                  <span
                    className={`${bundleClasses.label} text-ellipsis overflow-hidden whitespace-nowrap w-16`}
                  >
                    {bundleFilter?.coins}
                  </span>
                </Group>
                {bundleFilter && (
                  <CloseCircleFill
                    className="w-4 h-4 text-neutral-50 mr-2"
                    onClick={() => {
                      setBundleFilter(null);
                    }}
                  />
                )}
              </>
            ) : (
              <Text
                className="text-footer"
                styles={() => ({ root: { fontSize: 12 } })}
              >
                Select Bundle
              </Text>
            )}
            <ArrowDownOutline
              className={classNames(
                bundleClasses.icon,
                "w-4 h-4 text-neutral-40",
              )}
            />
          </UnstyledButton>
        </Menu.Target>
        <Menu.Dropdown className="max-h-80 overflow-y-auto">
          {bundleItem}
        </Menu.Dropdown>
      </Menu>
      <DatePickerInput
        size="xs"
        type="range"
        clearable
        icon={<Calendar2Outline className="w-4 h-4 text-neutral-40" />}
        value={selectedDateRange}
        numberOfColumns={2}
        styles={(theme) => ({
          root: {
            "> div": {
              borderColor: theme.colors.neutral[8],
            },
          },
        })}
        onChange={(value) => {
          const [start, end] = value;
          setSelectedDateRange(value);
          if (start) {
            addDiscountCodeForm.setFieldValue(
              "valid_from",
              `${start.getFullYear()}-${
                start.getMonth() + 1
              }-${start.getDate()}`,
            );
          }
          if (end) {
            addDiscountCodeForm.setFieldValue(
              "expires_at",
              `${end.getFullYear()}-${end.getMonth() + 1}-${end.getDate()}`,
            );
          }
        }}
        allowSingleDateSelectionInRange
        rightSection={
          selectedDateRange[1] ? null : (
            <ArrowDownOutline className="w-4 h-4 text-neutral-40" />
          )
        }
      />
      <Divider color="neutral.9" className="border-neutral-90" />
      <Box className="flex flex-col items-center justify-center">
        <Text className="text-p-01 text-neutral-30 text-center mb-4">
          Please enter the confirmation code you see on the authentication
          application
        </Text>
        <OTPInput
          radius={12}
          size="lg"
          allowedCharacters="numeric"
          className="flex justify-center"
          onChange={(res) => {
            addDiscountCodeForm.setFieldValue("otp_code", res);
          }}
        />
      </Box>
      <Button
        type="submit"
        disabled={!addDiscountCodeForm.isDirty()}
        loading={useAddDiscountCodeMutation.isPending}
        size="xs"
        fullWidth
      >
        Add discount code
      </Button>
    </Box>
  );
}

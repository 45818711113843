import {
  ActionIcon,
  Box,
  Menu,
  Switch,
  Table,
  Text,
} from "@axazara/raiton-atoms";
import {
  AddOutline,
  MoreOutline,
  EditOutline,
  TrashOutline,
} from "@axazara/raiton-icons";
import { openModal } from "@axazara/raiton-molecules";
import { Bundle, Bundles } from "utilities";
import { showErrorNotification, showSuccessNotification } from "@/utils";
import { useUpdateBundleVisibility } from "../services";
import { EditBundle } from "./EditBundle";
import { DeleteBundleModal } from "./DeleteBundleModal";

export function BundleTable({
  bundles,
  refetch,
}: {
  bundles: Bundles;
  refetch?: any;
}) {
  return (
    <Box className="w-full overflow-hidden rounded-2xl border border-neutral-80">
      <Table className="rounded-xl overflow-hidden border-collapse  border-spacing-0">
        <Table.Head className="text-p-01-semibold border-b border-neutral-80 text-neutral-20 rounded-xl !bg-neutral">
          <Table.HeadCell className="text-neutral-20 text-p-01-semibold">
            Id
          </Table.HeadCell>
          <Table.HeadCell className="text-neutral-20 text-p-01-semibold">
            Coin value
          </Table.HeadCell>
          <Table.HeadCell className="text-neutral-20 text-p-01-semibold">
            Normal price
          </Table.HeadCell>
          <Table.HeadCell className="text-neutral-20 text-p-01-semibold">
            Sale price
          </Table.HeadCell>
          <Table.HeadCell className="text-neutral-20 text-p-01-semibold">
            Public
          </Table.HeadCell>
          <Table.HeadCell className="text-neutral-20 text-p-01-semibold flex justify-end">
            View
          </Table.HeadCell>
        </Table.Head>
        <Table.Body>
          {bundles?.map((bundle) => (
            <BundleTableRow bundle={bundle} key={bundle.id} refetch={refetch} />
          ))}
        </Table.Body>
      </Table>
    </Box>
  );
}

function BundleTableRow({ bundle, refetch }: { bundle: Bundle; refetch: any }) {
  const useChangeBundleVisibilityMutation = useUpdateBundleVisibility({
    bundleId: bundle.id as string,
  });

  async function handleBundleVisibilityChange(value: boolean) {
    try {
      const response = await useChangeBundleVisibilityMutation.mutateAsync({
        is_public: value,
      });
      if (refetch) refetch();
      showSuccessNotification((response as any).message);
    } catch (error) {
      showErrorNotification((error as any).message);
    }
  }
  return (
    <Table.Row key={bundle.id} className="border-b border-neutral-90">
      <Table.Cell>
        <Text className="text-p-01 text-neutral-40">{bundle?.id}</Text>
      </Table.Cell>
      <Table.Cell>
        <Text className="text-p-01 text-neutral-40">{bundle.coins} coins</Text>
      </Table.Cell>
      <Table.Cell>
        <Text className="text-p-01 text-neutral-40">{bundle.price}</Text>
      </Table.Cell>
      <Table.Cell>
        <Text className="text-p-01 text-neutral-40">{bundle.sale_price}</Text>
      </Table.Cell>
      <Table.Cell>
        <Switch
          styles={(theme) => ({
            track: {
              minWidth: 52,
              borderRadius: 24,
              backgroundColor: theme.colors.neutral[8],
              border: 0,
            },
            root: {
              width: 52,
            },
            thumb: {
              border: "none",
              left: 4,
              "input:checked + * > &": {
                left: "calc(100% - 32px)",
              },
            },
          })}
          size="xl"
          checked={bundle.is_public}
          onChange={({ currentTarget }) =>
            handleBundleVisibilityChange(currentTarget.checked)
          }
          thumbIcon={
            bundle.is_public ? (
              <Box>
                <img src="/assets/images/mark.svg" alt="mark_icons" />
              </Box>
            ) : (
              <Box style={{ transform: "rotate(45deg)" }}>
                <AddOutline className=" text-neutral-30 w-6 h-6" />
              </Box>
            )
          }
        />
      </Table.Cell>

      <Table.Cell className="flex justify-end">
        <Menu withinPortal position="bottom-end">
          <Menu.Target>
            <ActionIcon size="xl" radius={100} className="p-0.5 bg-neutral-90">
              <MoreOutline className="w-6 h-6 text-neutral-50" />
            </ActionIcon>
          </Menu.Target>

          <Menu.Dropdown>
            <Menu.Item
              icon={<EditOutline className="w-5 h-5 text-primary-50" />}
              onClick={() =>
                openModal({
                  centered: true,
                  children: <EditBundle bundle={bundle} />,
                  size: "md",
                  title: (
                    <Text className="text-heading-05-bold text-neutral-30">
                      Edit bundle
                    </Text>
                  ),
                })
              }
            >
              <Text className="text-p-01-medium">Edit bundle</Text>
            </Menu.Item>
            <Menu.Item
              icon={<TrashOutline className="w-5 h-5 text-critical-50" />}
              className="hover:bg-critical"
              onClick={() =>
                openModal({
                  title: (
                    <Text className="text-heading-04-sm-semibold">
                      Confirm deletion
                    </Text>
                  ),
                  centered: true,
                  children: <DeleteBundleModal bundle={bundle} />,
                })
              }
            >
              <Text className="text-p-01-medium text-critical-40">Delete</Text>
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Table.Cell>
    </Table.Row>
  );
}

export const API_ENDPOINTS = {
  CORE: {
    AUTH: {
      CONNECT: "/auth/staff/login",
      LOGOUT: "/staff/logout",
      PROFILE: "/staff/profile",
      INVITATIONS: "/auth/staff/invite/:inviteCode",
      OTPRESET: "/auth/staff/reset-two-factor/:token",
    },
  },

  USERS: {
    ROOT: "/users",
    BY_ID: "/users/:userId",
    USER: {
      ORDERS: "/users/:userId/orders",
      TRANSACTIONS: "/users/:userId/transactions",
      SUMMARY: "/users/:userId/summary",
      REFILLS: "/users/:userId/refills",
    },
  },
  STAFFS: {
    ROOT: "/staffs/members",
    BY_ID: "/staffs/members/:staffId",
    INVITATIONS: {
      ROOT: "/staffs/invites",
      BY_ID: "/staffs/invites/:staffId",
      RESEND_INVITATIONS: "/staffs/invites/:staffId/resend",
    },

    ACTIVITIES: "/staffs/activities",
    STAFF: {
      ACTIVITY: "/staffs/activities/:staffId",
      ACTIVITIES: "/staffs/members/:staffId/activities",
    },
    AUTH: {
      RESET_STAFF_OTP: "/staffs/members/:staffId/reset-two-factor",
    },
  },
  BUNDLES: {
    ROOT: "/bundles",
    BY_ID: "/bundles/:bundleId",
    DELETE_BUNDLE: "/bundles/:bundleId/delete",
  },

  UTILS: {
    SERVICES: "/utils/services",
    COUNTRIES: "/utils/countries",
    CURRENCIES: "/utils/currencies",
    REFUND_REASONS: "/utils/refund-reasons",
    PROVIDERS: "/utils/providers",
    PROVIDER_BY_CODE: "/utils/providers/:code",
  },

  DISCOUNTS: {
    ROOT: "/discounts",
    BY_ID: "/discounts/:discountId",
    DELETE_DISCOUNT: "/discounts/:discountId/delete",
  },

  ORDERS: {
    ROOT: "/orders",
    BY_ID: "/orders/:orderId",
    MESSAGES: "/orders/:orderId/messages",
    REFUND: "/orders/:orderId/refund",
  },
  PRICING: {
    ROOT: "/pricing",
    BY_ID: "/pricing/:pricingId",
  },
  PROVIDERS_INSTANCE: {
    ROOT: "/providers",
    BY_ID: "/providers/:providerId",
  },

  REFILLS: {
    ROOT: "/refills",
    BY_ID: "/refills/:refillId",
    RECHECK: "/refills/:refillId/recheck",
  },

  TRANSACTIONS: {
    ROOT: "/transactions",
  },
};

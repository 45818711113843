import {
  formatDate,
  PaginatedProviderAccounts,
  Provider,
  ProviderAccount,
  Providers,
} from "utilities";
import { DateRange } from "@/types";
import { buildFetchParams } from "@axazara/raiton-utils";
import { useGet, usePost, usePut } from "@/lib";
import { API_ENDPOINTS } from "@/configs";
import { formatPath } from "@axazara/shuriken";

export const useGetInstanceProviders = ({
  page = 1,
  search,
  initialData,
  dateRange = [null, null],
}: {
  page?: number;
  search?: string;
  initialData?: PaginatedProviderAccounts;
  dateRange?: DateRange;
}) => {
  const requestPage = search ? 1 : page;
  const [fromDate, toDate] = dateRange;

  const params = buildFetchParams({
    page: requestPage,
    search,
    from:
      fromDate && formatDate(fromDate.toDateString(), undefined, "YYYY-MM-DD"),
    to: toDate && formatDate(toDate.toDateString(), undefined, "YYYY-MM-DD"),
  });
  return useGet<PaginatedProviderAccounts>({
    url: API_ENDPOINTS.PROVIDERS_INSTANCE.ROOT,
    params,
    queryOptions: { initialData },
  });
};

export const useUpdateProviderInformation = (providerId: string) => {
  return usePut<Partial<ProviderAccount>, ProviderAccount>({
    url: formatPath(API_ENDPOINTS.PROVIDERS_INSTANCE.BY_ID, { providerId }),
  });
};
export const useGetProviders = ({ search }: { search?: string }) => {
  const params = buildFetchParams({
    search,
  });
  return useGet<Providers>({
    url: API_ENDPOINTS.UTILS.PROVIDERS,
    params,
  });
};
export const useGetProvider = (code: string) => {
  return useGet<Provider>({
    url: formatPath(API_ENDPOINTS.UTILS.PROVIDER_BY_CODE, { code }),
  });
};
export const useAddProviderAccount = () =>
  usePost<Partial<Provider>, Provider>({
    url: API_ENDPOINTS.PROVIDERS_INSTANCE.ROOT,
  });

import { API_ENDPOINTS } from "@/configs";
import { useGet, usePost } from "@/lib";
import { DateRange } from "@/types";
import { buildFetchParams } from "@axazara/raiton-utils";
import { formatPath } from "@axazara/shuriken";
import { PaginatedRefills, Refill, formatDate } from "utilities";

export const useGetRefills = ({
  page,
  search,
  status = "",
  initialData,
  dateRange = [null, null],
}: {
  page?: number;
  search?: string;
  initialData?: PaginatedRefills;
  status?: string;
  dateRange?: DateRange;
}) => {
  const [fromDate, toDate] = dateRange;

  const params = buildFetchParams({
    from:
      fromDate && formatDate(fromDate.toDateString(), undefined, "YYYY-MM-DD"),
    to: toDate && formatDate(toDate.toDateString(), undefined, "YYYY-MM-DD"),
    page,
    search,
    status,
  });
  return useGet<PaginatedRefills>({
    url: API_ENDPOINTS.REFILLS.ROOT,
    params,
    queryOptions: {
      initialData,
    },
  });
};

export const useGetRefill = (refillId: string) => {
  return useGet<Refill>({
    url: formatPath(API_ENDPOINTS.REFILLS.BY_ID, { refillId }),
  });
};
export const useRecheckRefill = (refillId: string) => {
  return usePost<undefined, any>({
    url: formatPath(API_ENDPOINTS.REFILLS.RECHECK, { refillId }),
  });
};

import {
  ActionIcon,
  Box,
  Menu,
  Switch,
  Table,
  Text,
} from "@axazara/raiton-atoms";
import { openModal } from "@axazara/raiton-molecules";
import { DeleteDiscountCodeModal, EditDiscountCodeModal } from ".";
import { Discount, Discounts, formatDate } from "utilities";
import {
  AddOutline,
  EditOutline,
  MoreOutline,
  TrashOutline,
} from "@axazara/raiton-icons";
import { showErrorNotification, showSuccessNotification } from "@/utils";
import { useUpdateDiscountCodeVisibility } from "../services";

export function DiscountCodeTable({
  discountCode,
  refetch,
}: {
  discountCode: Discounts;
  refetch: any;
}) {
  return (
    <Box>
      <Box className="w-full overflow-hidden rounded-2xl border border-neutral-80">
        <Table className="rounded-xl overflow-hidden border-collapse  border-spacing-0">
          <Table.Head className="text-p-01-semibold border-b border-neutral-80 text-neutral-20 rounded-xl !bg-neutral">
            <Table.HeadCell className="text-neutral-20 text-p-01-semibold">
              Reduction code
            </Table.HeadCell>
            <Table.HeadCell className="text-neutral-20 text-p-01-semibold">
              Bundle
            </Table.HeadCell>
            <Table.HeadCell className="text-neutral-20 text-p-01-semibold">
              Expire
            </Table.HeadCell>
            <Table.HeadCell className="text-neutral-20 text-p-01-semibold">
              Reduction
            </Table.HeadCell>
            <Table.HeadCell className="text-neutral-20 text-p-01-semibold">
              Total Use
            </Table.HeadCell>
            <Table.HeadCell className="text-neutral-20 text-p-01-semibold">
              Status
            </Table.HeadCell>
            <Table.HeadCell className="text-neutral-20 text-p-01-semibold flex justify-end">
              View
            </Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y divide-neutral-80">
            {discountCode?.map((discount) => (
              <DiscountCodeTableRow
                key={discount.id}
                discount={discount}
                refetch={refetch}
              />
            ))}
          </Table.Body>
        </Table>
      </Box>
    </Box>
  );
}

function DiscountCodeTableRow({
  discount,
  refetch,
}: {
  discount: Discount;
  refetch: any;
}) {
  const useChangeDiscountCodeVisibilityMutation =
    useUpdateDiscountCodeVisibility({ discountId: discount.id as string });

  async function handleUpdateDiscountCodeVisibility(value: boolean) {
    try {
      const response =
        await useChangeDiscountCodeVisibilityMutation.mutateAsync({
          is_enabled: value,
        });
      if (refetch) refetch();
      showSuccessNotification((response as any).message);
    } catch (error) {
      showErrorNotification((error as any).message);
    }
  }
  return (
    <Table.Row key={discount.id}>
      <Table.Cell className="text-p-01 text-neutral-40">
        {discount.code}
      </Table.Cell>
      <Table.Cell>
        <Text className="text-p-01 text-neutral-40">
          {discount.bundle?.coins}
        </Text>
      </Table.Cell>
      <Table.Cell>
        <Text className="text-p-01 text-neutral-40">
          {formatDate(discount.valid_from, "en", "DD MMM YYYY")}-
          {formatDate(discount.expires_at, "en", "DD MMM YYYY")}
        </Text>
      </Table.Cell>
      <Table.Cell>
        <Text className="text-p-01 text-neutral-40">{discount.value}</Text>
      </Table.Cell>
      <Table.Cell>
        <Text className="text-p-01 text-neutral-40">{discount.total_use}</Text>
      </Table.Cell>
      <Table.Cell>
        <Switch
          styles={(theme) => ({
            track: {
              minWidth: 52,
              borderRadius: 24,
              backgroundColor: theme.colors.neutral[8],
              border: 0,
            },
            root: {
              width: 52,
            },
            thumb: {
              border: "none",
              left: 4,
              "input:checked + * > &": {
                left: "calc(100% - 32px)",
              },
            },
          })}
          checked={discount.is_enabled}
          onChange={({ currentTarget }) =>
            handleUpdateDiscountCodeVisibility(currentTarget.checked)
          }
          thumbIcon={
            discount.is_enabled ? (
              <Box>
                <svg
                  width="10"
                  height="7"
                  viewBox="0 0 10 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.25 3.5L3.91353 6L9.25 1"
                    stroke="#09A034"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </Box>
            ) : (
              <Box style={{ transform: "rotate(45deg)" }}>
                <AddOutline className=" text-neutral-30 w-6 h-6" />
              </Box>
            )
          }
          size="xl"
        />
      </Table.Cell>
      <Table.Cell className="flex justify-end">
        <Menu withinPortal position="bottom-end">
          <Menu.Target>
            <ActionIcon size="xl" radius={100} className="p-0.5 bg-neutral-90">
              <MoreOutline className="w-6 h-6 text-neutral-50" />
            </ActionIcon>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item
              icon={<EditOutline className="w-5 h-5 text-primary-50" />}
              onClick={() =>
                openModal({
                  centered: true,
                  children: <EditDiscountCodeModal discount={discount} />,
                  size: "lg",
                  title: (
                    <Text className="text-heading-05-bold text-neutral-30">
                      Edit discount code
                    </Text>
                  ),
                })
              }
            >
              <Text className="text-p-01-medium">Edit discount code</Text>
            </Menu.Item>
            <Menu.Item
              icon={<TrashOutline className="w-5 h-5 text-critical-50" />}
              className="hover:bg-critical"
              onClick={() =>
                openModal({
                  title: (
                    <Text className="text-heading-04-sm-semibold">
                      Confirm deletion
                    </Text>
                  ),
                  centered: true,
                  children: <DeleteDiscountCodeModal discount={discount} />,
                })
              }
            >
              <Text className="text-p-01-medium text-critical-40">Delete</Text>
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Table.Cell>
    </Table.Row>
  );
}

import { API_ENDPOINTS } from "@/configs";
import { useGet, usePost } from "@/lib";
import { AuthInvite, LoginPayload, LoginResponse } from "@/models";
import { formatPath } from "@axazara/shuriken";

export const useLoginWithOTP = () => {
  return usePost<LoginPayload, LoginResponse>({
    url: API_ENDPOINTS.CORE.AUTH.CONNECT,
  });
};

export const useGetInvitation = (inviteCode: string) => {
  return useGet<AuthInvite>({
    url: formatPath(API_ENDPOINTS.CORE.AUTH.INVITATIONS, { inviteCode }),
  });
};

export const useAcceptInvitation = (inviteCode: string) => {
  return usePost<{ otp_code: string }, { message: string }>({
    url: formatPath(API_ENDPOINTS.CORE.AUTH.INVITATIONS, { inviteCode }),
  });
};

export const useGetOTPReset = (token: string) => {
  return useGet<AuthInvite>({
    url: formatPath(API_ENDPOINTS.CORE.AUTH.OTPRESET, { token }),
  });
};

export const useResetOTP = (token: string) => {
  return usePost<{ otp_code: string }, { message: string }>({
    url: formatPath(API_ENDPOINTS.CORE.AUTH.OTPRESET, { token }),
  });
};

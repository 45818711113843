import { API_ENDPOINTS } from "@/configs";
import { useGet } from "@/lib";
import { DateRange } from "@/types";
import { buildFetchParams } from "@axazara/raiton-utils";
import { PaginatedTransactions, formatDate } from "utilities";

export const useGetTransactions = ({
  page,
  search,
  initialData,
  type = "",
  dateRange = [null, null],
}: {
  page?: number;
  search?: string;
  type?: string;
  initialData?: PaginatedTransactions;
  dateRange?: DateRange;
}) => {
  const [fromDate, toDate] = dateRange;

  const params = buildFetchParams({
    from:
      fromDate && formatDate(fromDate.toDateString(), undefined, "YYYY-MM-DD"),
    to: toDate && formatDate(toDate.toDateString(), undefined, "YYYY-MM-DD"),
    page,
    search,
    type,
  });
  return useGet<PaginatedTransactions>({
    url: API_ENDPOINTS.TRANSACTIONS.ROOT,
    params,
    queryOptions: { initialData },
  });
};

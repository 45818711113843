import { API_ENDPOINTS } from "@/configs";
import { useGet } from "@/lib";
import { buildFetchParams } from "@axazara/raiton-utils";
import { Services } from "utilities";

export const useGetServices = ({ search }: { search?: string }) => {
  const params = buildFetchParams({
    search,
  });

  return useGet<Services>({
    url: API_ENDPOINTS.UTILS.SERVICES,
    params,
  });
};

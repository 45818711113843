import { API_ENDPOINTS } from "@/configs";
import { useGet, usePost } from "@/lib";
import { DateRange } from "@/types";
import { buildFetchParams } from "@axazara/raiton-utils";
import { formatPath } from "@axazara/shuriken";
import {
  Countries,
  Order,
  OrderMessages,
  PaginatedOrders,
  RefundOrderPayload,
  RefundReasons,
  Services,
  formatDate,
} from "utilities";

export const useGetOrders = ({
  page = 1,
  search,
  initialData,
  dateRange = [null, null],
  status = "",
  country = "",
  service = "",
  provider_id = "",
}: {
  page?: number;
  search?: string;
  initialData?: PaginatedOrders;
  dateRange?: DateRange;
  status?: string;
  service?: string;
  country?: string;
  provider_id?: string;
}) => {
  const requestedPage = search ? 1 : page;
  const [fromDate, toDate] = dateRange;

  let params = buildFetchParams({
    page: requestedPage,
    search,
    status,
    country,
    service,
    provider_id,
    from:
      fromDate && formatDate(fromDate.toDateString(), undefined, "YYYY-MM-DD"),
    to: toDate && formatDate(toDate.toDateString(), undefined, "YYYY-MM-DD"),
  });

  return useGet<PaginatedOrders>({
    url: API_ENDPOINTS.ORDERS.ROOT,
    params,
    queryOptions: { initialData },
  });
};

export const useGetOrdersDetails = (orderId: string) => {
  return useGet<Order>({
    url: formatPath(API_ENDPOINTS.ORDERS.BY_ID, { orderId }),
  });
};

export const useGetOrderMessages = (orderId: string) => {
  return useGet<OrderMessages>({
    url: formatPath(API_ENDPOINTS.ORDERS.MESSAGES, { orderId }),
  });
};

export const useGetServices = ({
  page,
  search,
}: {
  page?: number;
  search?: string;
}) => {
  const params = buildFetchParams({
    page,
    search,
  });
  return useGet<Services>({
    url: API_ENDPOINTS.UTILS.SERVICES,
    params,
  });
};

export const useGetCountries = ({
  page,
  search,
}: {
  page?: number;
  search?: string;
}) => {
  const params = buildFetchParams({
    page,
    search,
  });
  return useGet<Countries>({
    url: API_ENDPOINTS.UTILS.COUNTRIES,
    params,
  });
};

export const useRefundOrder = ({ orderId }: { orderId: string }) => {
  return usePost<RefundOrderPayload, { message: string }>({
    url: formatPath(API_ENDPOINTS.ORDERS.REFUND, { orderId }),
  });
};

export const useGetRefundOrderReasons = () =>
  useGet<RefundReasons>({
    url: API_ENDPOINTS.UTILS.REFUND_REASONS,
  });

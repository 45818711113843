import { buildFetchParams } from "@axazara/raiton-utils";
import { useDelete, useGet, usePost, usePut } from "@/lib";
import { API_ENDPOINTS } from "@/configs";
import {
  PaginatedStaffs,
  PaginatedInvitations,
  PaginatedActivities,
  Activity,
  Staff,
} from "@/models";
import { formatDate } from "utilities";
import { DateRange } from "@/types";
import { formatPath } from "@axazara/shuriken";

export const useGetStaffsData = ({
  page = 1,
  search = "",
}: {
  page: number;
  search?: string;
}) => {
  const params = buildFetchParams({
    page,
    search,
  });

  return useGet<PaginatedStaffs>({
    url: API_ENDPOINTS.STAFFS.ROOT,
    params,
  });
};

export const useGetInvitations = ({
  page,
  search = "",
}: {
  page?: number;
  search?: string;
}) => {
  const params = buildFetchParams({
    page,
    search,
  });

  return useGet<PaginatedInvitations>({
    url: API_ENDPOINTS.STAFFS.INVITATIONS.ROOT,
    params,
  });
};

export const useGetActivities = ({
  page = 1,
  search = "",
  dateRange = [null, null],
}: {
  page?: number;
  search?: string;
  dateRange?: DateRange;
}) => {
  const [fromDate, toDate] = dateRange;

  const params = buildFetchParams({
    page,
    search,
    from:
      fromDate && formatDate(fromDate.toDateString(), undefined, "YYYY-MM-DD"),
    to: toDate && formatDate(toDate.toDateString(), undefined, "YYYY-MM-DD"),
  });

  return useGet<PaginatedActivities>({
    url: API_ENDPOINTS.STAFFS.ACTIVITIES,
    params,
  });
};

export const useGetActivity = (staffId: string) => {
  return useGet<Activity>({
    url: formatPath(API_ENDPOINTS.STAFFS.STAFF.ACTIVITY, { staffId }),
  });
};

export const useGetStaffActivities = (
  staffId: string,
  {
    page = 1,
    dateRange = [null, null],
    search = "",
  }: { page?: number; dateRange?: DateRange; search?: string },
) => {
  const [fromDate, toDate] = dateRange;

  const params = buildFetchParams({
    page,
    search,
    from:
      fromDate && formatDate(fromDate.toDateString(), undefined, "YYYY-MM-DD"),
    to: toDate && formatDate(toDate.toDateString(), undefined, "YYYY-MM-DD"),
  });

  const url = formatPath(API_ENDPOINTS.STAFFS.STAFF.ACTIVITIES, { staffId });

  return useGet<PaginatedActivities>({
    url,
    params,
  });
};

export const useCreateStaff = () => {
  return usePost<Partial<Staff>, Staff>({
    url: API_ENDPOINTS.STAFFS.INVITATIONS.ROOT,
  });
};

export const useUpdateStaffProfile = (staffId: string) => {
  return usePut<Partial<Staff>, Staff>({
    url: formatPath(API_ENDPOINTS.STAFFS.BY_ID, { staffId }),
  });
};

export const useResetStaffOTP = (staffId: string) => {
  return usePost<{ otp_code: string }, { message: string }>({
    url: formatPath(API_ENDPOINTS.STAFFS.AUTH.RESET_STAFF_OTP, { staffId }),
  });
};
export const useGetStaff = (staffId: string) => {
  return useGet<Staff>({
    url: formatPath(API_ENDPOINTS.STAFFS.BY_ID, { staffId }),
  });
};

export const useDeleteInvitation = (staffId: string) => {
  return useDelete({
    url: formatPath(API_ENDPOINTS.STAFFS.INVITATIONS.BY_ID, { staffId }),
  });
};

export const useResendInvitation = (staffId: string) => {
  return usePost({
    url: formatPath(API_ENDPOINTS.STAFFS.INVITATIONS.RESEND_INVITATIONS, {
      staffId,
    }),
  });
};

import { API_ENDPOINTS } from "@/configs";
import { useGet, usePost, usePut } from "@/lib";
import { buildFetchParams } from "@axazara/raiton-utils";
import { formatPath } from "@axazara/shuriken";
import {
  AddPricingPayload,
  Countries,
  PaginatedPricingList,
  PaginatedProviderAccounts,
  Pricing,
  PricingPayload,
  Services,
} from "utilities";

export const useGetPricings = ({
  page,
  search,
  country = "",
  service = "",
  provider_id = "",
}: {
  page?: number;
  search?: string;
  country?: string;
  service?: string;
  provider_id?: string;
}) => {
  const params = buildFetchParams({
    page,
    search,
    country,
    service,
    provider_id,
  });

  return useGet<PaginatedPricingList>({
    url: API_ENDPOINTS.PRICING.ROOT,
    params,
  });
};

export const useUpdatePricing = ({ pricingId }: { pricingId: string }) => {
  return usePut<Partial<PricingPayload>, Pricing>({
    url: formatPath(API_ENDPOINTS.PRICING.BY_ID, { pricingId }),
  });
};
export const useAddPricing = () => {
  return usePost<AddPricingPayload, Pricing>({
    url: API_ENDPOINTS.PRICING.ROOT,
  });
};

export const useGetPricingCountries = ({
  page,
  search,
}: {
  page?: number;
  search?: string;
}) => {
  const params = buildFetchParams({
    page,
    search,
  });
  return useGet<Countries>({
    url: API_ENDPOINTS.UTILS.COUNTRIES,
    params,
  });
};

export const useGetPricingServices = ({
  page,
  search,
}: {
  page?: number;
  search?: string;
}) => {
  const params = buildFetchParams({
    page,
    search,
  });
  return useGet<Services>({
    url: API_ENDPOINTS.UTILS.SERVICES,
    params,
  });
};

export const useGetProviders = ({
  page,
  search,
}: {
  page?: number;
  search?: string;
}) => {
  const params = buildFetchParams({
    page,
    search,
  });

  return useGet<PaginatedProviderAccounts>({
    url: API_ENDPOINTS.PROVIDERS_INSTANCE.ROOT,
    params,
  });
};

export const useUpdatePricingStatus = ({
  pricingId,
}: {
  pricingId: string;
}) => {
  return usePut<Partial<Pricing>, Pricing>({
    url: formatPath(API_ENDPOINTS.PRICING.BY_ID, { pricingId }),
  });
};

import {
  ActionIcon,
  Avatar,
  Box,
  Switch,
  Table,
  Text,
} from "@axazara/raiton-atoms";
import { SearchStatusOutline, AddOutline } from "@axazara/raiton-icons";
import { openModal } from "@axazara/raiton-molecules";
import { Pricing, PricingList } from "utilities";
import { showErrorNotification, showSuccessNotification } from "@/utils";
import { useUpdatePricingStatus } from "../services";
import { EditPricing } from "./EditPricing";

export function PricingTable({
  pricings,
  refetch,
}: {
  pricings: PricingList;
  refetch?: any;
}) {
  return (
    <Box className="w-full overflow-hidden rounded-2xl border border-neutral-80">
      <Table className="rounded-xl overflow-hidden border-collapse  border-spacing-0">
        <Table.Head className="text-p-01-semibold border-b border-neutral-80 text-neutral-20 rounded-xl !bg-neutral">
          <Table.HeadCell className="text-neutral-20 text-p-01-semibold">
            Service
          </Table.HeadCell>
          <Table.HeadCell className="text-neutral-20 text-p-01-semibold">
            Country
          </Table.HeadCell>
          <Table.HeadCell className="text-neutral-20 text-p-01-semibold">
            Normal price
          </Table.HeadCell>
          <Table.HeadCell className="text-neutral-20 text-p-01-semibold">
            Sale price
          </Table.HeadCell>
          <Table.HeadCell className="text-neutral-20 text-p-01-semibold">
            Status
          </Table.HeadCell>
          <Table.HeadCell className="text-neutral-20 text-p-01-semibold">
            Provider
          </Table.HeadCell>
          <Table.HeadCell className="text-neutral-20 text-p-01-semibold flex justify-end">
            View
          </Table.HeadCell>
        </Table.Head>
        <Table.Body className="divide-y divide-neutral-80">
          {pricings?.map((pricing) => (
            <PricingTableRow
              pricing={pricing}
              key={pricing.id}
              refetch={refetch}
            />
          ))}
        </Table.Body>
      </Table>
    </Box>
  );
}

function PricingTableRow({
  pricing,
  refetch,
}: {
  pricing: Pricing;
  refetch: any;
}) {
  const useChangePricingStatusMutation = useUpdatePricingStatus({
    pricingId: pricing.id as string,
  });

  async function handlePricingStatusChange(value: boolean) {
    try {
      const response = await useChangePricingStatusMutation.mutateAsync({
        is_public: value,
      });
      if (refetch) refetch();
      showSuccessNotification((response as any).message);
    } catch (error) {
      showErrorNotification((error as any).message);
    }
  }
  return (
    <Table.Row key={pricing.id}>
      <Table.Cell>
        <Box className="md:flex-row flex flex-col items-center space-x-2.5">
          <Avatar src={pricing.service.icon_url} size={48} />
          <Text className="text-p-01-medium text-neutral-40">
            {pricing.service.name}
          </Text>
        </Box>
      </Table.Cell>
      <Table.Cell className="text-neutral-40 text-p-01-medium">
        <Box className="md:flex-row flex flex-col items-center space-x-4">
          <Avatar src={pricing.country.flag} size={32} />
          <Text className="text-p-01-medium text-neutral-40">
            {pricing.country.name}
          </Text>
        </Box>
      </Table.Cell>
      <Table.Cell>
        <Text className="text-p-01-medium text-neutral-40">
          {pricing.price}
        </Text>
      </Table.Cell>
      <Table.Cell>
        <Text className="text-p-01-medium text-neutral-40">
          {pricing.sale_price}
        </Text>
      </Table.Cell>
      <Table.Cell>
        <Switch
          styles={(theme) => ({
            track: {
              minWidth: 52,
              borderRadius: 24,
              backgroundColor: theme.colors.neutral[8],
              border: 0,
            },
            root: {
              width: 52,
            },
            thumb: {
              border: "none",
              left: 4,
              "input:checked + * > &": {
                left: "calc(100% - 32px)",
              },
            },
          })}
          size="xl"
          checked={pricing.is_public}
          onChange={({ currentTarget }) =>
            handlePricingStatusChange(currentTarget.checked)
          }
          thumbIcon={
            pricing.is_public ? (
              <Box>
                <img src="/assets/images/mark.svg" alt="mark_icons" />
              </Box>
            ) : (
              <Box style={{ transform: "rotate(45deg)" }}>
                <AddOutline className="text-neutral-30 w-6 h-6" />
              </Box>
            )
          }
        />
      </Table.Cell>
      <Table.Cell className="text-p-01-medium">
        <Box className="md:flex-row flex flex-col items-center space-x-4">
          <Avatar src={pricing.provider?.provider?.icon_url} size={48} />
          <Text className="text-p-01-medium text-neutral-20">
            {pricing.provider.name}
          </Text>
        </Box>
      </Table.Cell>
      <Table.Cell className="flex justify-end">
        <Box className="flex space-x-2">
          <ActionIcon
            color="primary"
            className="p-0.5"
            size="xl"
            radius={100}
            onClick={() =>
              openModal({
                centered: true,
                children: <EditPricing pricing={pricing} />,
                size: "md",
                title: (
                  <Text className="text-heading-05-bold text-neutral-30">
                    Edit pricing
                  </Text>
                ),
              })
            }
          >
            <SearchStatusOutline className="w-6 h-6 text-primary-50" />
          </ActionIcon>
        </Box>
      </Table.Cell>
    </Table.Row>
  );
}

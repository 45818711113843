import React from "react";
import {
  Avatar,
  Box,
  Button,
  Group,
  InputField,
  Menu,
  OTPInput,
  Text,
  UnstyledButton,
} from "@axazara/raiton-atoms";
import { closeModal, useForm } from "@axazara/raiton-molecules";
import {
  AddPricingPayload,
  Country,
  ProviderAccount,
  Service,
  useFilterDropdownStyles,
} from "utilities";
import { showErrorNotification, showSuccessNotification } from "@/utils";
import { classNames, useDebouncedValue } from "@axazara/raiton-utils";
import {
  SearchNormal1Outline,
  CloseCircleFill,
  ArrowDownOutline,
} from "@axazara/raiton-icons";
import {
  useAddPricing,
  useGetPricingCountries,
  useGetProviders,
  useGetPricingServices,
} from "../services";
import { useQueryClient } from "@tanstack/react-query";
import { API_ENDPOINTS } from "@/configs";

export function AddPricing() {
  const queryClient = useQueryClient();

  const [countryOpened, setCountryOpened] = React.useState(false);
  const [serviceOpened, setServiceOpened] = React.useState(false);
  const [providerOpened, setProviderOpened] = React.useState(false);

  const { classes: countryClasses } = useFilterDropdownStyles({
    opened: countryOpened,
    width: "100%",
  });
  const { classes: serviceClasses } = useFilterDropdownStyles({
    opened: serviceOpened,
    width: "100%",
  });
  const { classes: providerClasses } = useFilterDropdownStyles({
    opened: providerOpened,
    width: "100%",
  });

  const [searchCountryQuery, setSearchCountryQuery] = React.useState("");
  const [debouncedCountrySearch] = useDebouncedValue(searchCountryQuery, 500);

  const [searchServiceQuery, setSearchServiceQuery] = React.useState("");
  const [debouncedServiceSearch] = useDebouncedValue(searchServiceQuery, 500);

  const [searchProviderQuery, setSearchProviderQuery] = React.useState("");
  const [debouncedProviderSearch] = useDebouncedValue(searchProviderQuery, 500);

  const [countryFilter, setCountryFilter] = React.useState<Country | null>();
  const [serviceFilter, setServiceFilter] = React.useState<Service | null>();
  const [providerFilter, setProviderFilter] =
    React.useState<ProviderAccount | null>();

  const { data: countries } = useGetPricingCountries({
    page: 1,
    search: debouncedCountrySearch,
  });
  const { data: services } = useGetPricingServices({
    page: 1,
    search: debouncedServiceSearch,
  });
  const { data: providers } = useGetProviders({
    page: 1,
    search: debouncedProviderSearch,
  });

  const addPricingFormData = useForm<AddPricingPayload>({
    initialValues: {
      provider_id: "",
      country_code: "",
      price: undefined,
      otp_code: "",
      service_short_code: "",
    },
  });
  const addPricingMutation = useAddPricing();
  async function handleAddPricing(values: AddPricingPayload) {
    try {
      const response = await addPricingMutation.mutateAsync(values);
      queryClient.invalidateQueries({ queryKey: [API_ENDPOINTS.PRICING.ROOT] });

      showSuccessNotification((response as any)?.message);

      closeModal("");
    } catch (error) {
      showErrorNotification((error as any)?.message);
    }
  }

  const countryItems = countries?.map((item) => (
    <Menu.Item
      className={countryFilter?.name === item.name ? "bg-neutral-90" : ""}
      icon={<Avatar size={24} src={item?.flag} />}
      onClick={() => {
        addPricingFormData.setFieldValue("country_code", item.code);
        setCountryFilter(item);
      }}
      key={item?.name}
    >
      {item?.name}
    </Menu.Item>
  ));

  const serviceItems = services?.map((item) => (
    <Menu.Item
      className={serviceFilter?.name === item.name ? "bg-neutral-90" : ""}
      icon={<Avatar size={24} src={item?.icon_url} />}
      onClick={() => {
        addPricingFormData.setFieldValue("service_short_code", item.short_code);
        setServiceFilter(item);
      }}
      key={item?.name}
    >
      {item?.name}
    </Menu.Item>
  ));

  const providerItems = providers?.data?.map((item: ProviderAccount) => (
    <Menu.Item
      className={providerFilter?.name === item?.name ? "bg-neutral-90" : ""}
      icon={<Avatar size={24} src={item?.provider?.icon_url} />}
      onClick={() => {
        addPricingFormData.setFieldValue("provider_id", item?.id);
        setProviderFilter(item);
      }}
      key={item?.id}
    >
      <Box className="flex flex-col space-y-1">
        <span className="text-p-01-medium">{item?.name}</span>
        <span className="text-footer">{item?.provider?.name}</span>
      </Box>
    </Menu.Item>
  ));

  return (
    <Box className="mt-12">
      <Box
        component="form"
        onSubmit={addPricingFormData.onSubmit(handleAddPricing)}
      >
        <Box className="flex flex-col space-y-4">
          <Box className="w-full">
            <Menu
              onOpen={() => setServiceOpened(true)}
              onClose={() => setServiceOpened(false)}
              radius="md"
              withinPortal
            >
              <Menu.Target>
                <UnstyledButton
                  className={`${serviceClasses.control} space-x-4`}
                >
                  {serviceOpened ? (
                    <InputField
                      onClick={(e) => e.stopPropagation()}
                      classNames={{
                        input:
                          "rounded-none !pl-5 !border-0 focus:shadow-none !outline-none focus:border-0 focus-within:border-0 focus-within:border-transparent focus:border-transparent focus:appearance-none  h-[38px] min-h-[38px]",
                      }}
                      value={searchServiceQuery}
                      className="w-full"
                      iconWidth={12}
                      size="xs"
                      onChange={(e) => setSearchServiceQuery(e.target.value)}
                      icon={<SearchNormal1Outline className="w-3 h-3" />}
                    />
                  ) : serviceFilter ? (
                    <>
                      <Group spacing="xs" className="w-full">
                        {serviceFilter?.icon_url && (
                          <Avatar size={24} src={serviceFilter?.icon_url} />
                        )}
                        <span
                          className={`${serviceClasses.label} text-ellipsis overflow-hidden whitespace-nowrap w-16`}
                        >
                          {serviceFilter?.name}
                        </span>
                      </Group>
                      {serviceFilter && (
                        <CloseCircleFill
                          className="w-4 h-4 text-neutral-50 mr-2"
                          onClick={() => {
                            setServiceFilter(null);
                          }}
                        />
                      )}
                    </>
                  ) : (
                    <Text
                      className="text-footer"
                      styles={() => ({ root: { fontSize: 12 } })}
                    >
                      Select service
                    </Text>
                  )}
                  <ArrowDownOutline
                    className={classNames(
                      serviceClasses.icon,
                      "w-4 h-4 text-neutral-40",
                    )}
                  />
                </UnstyledButton>
              </Menu.Target>
              <Menu.Dropdown style={{ maxHeight: "300px", overflow: "auto" }}>
                {serviceItems}
              </Menu.Dropdown>
            </Menu>
          </Box>
          <Box className="w-full">
            <Menu
              onOpen={() => setCountryOpened(true)}
              onClose={() => setCountryOpened(false)}
              radius="md"
              withinPortal
            >
              <Menu.Target>
                <UnstyledButton
                  className={`${countryClasses.control} space-x-4`}
                >
                  {countryOpened ? (
                    <InputField
                      onClick={(e) => e.stopPropagation()}
                      classNames={{
                        input:
                          "rounded-none !pl-5 !border-0 focus:shadow-none !outline-none focus:border-0 focus-within:border-0 focus-within:border-transparent focus:border-transparent focus:appearance-none  h-[38px] min-h-[38px]",
                      }}
                      value={searchCountryQuery}
                      className="w-full"
                      iconWidth={12}
                      size="xs"
                      onChange={(e) => setSearchCountryQuery(e.target.value)}
                      icon={<SearchNormal1Outline className="w-3 h-3" />}
                    />
                  ) : countryFilter ? (
                    <>
                      <Group spacing="xs" className="w-full">
                        {countryFilter?.flag && (
                          <Avatar size={24} src={countryFilter?.flag} />
                        )}
                        <span className={`${countryClasses.label}`}>
                          {countryFilter?.name}
                        </span>
                      </Group>
                      {countryFilter && (
                        <CloseCircleFill
                          className="w-4 h-4 text-neutral-50 mr-2"
                          onClick={() => {
                            setCountryFilter(null);
                          }}
                        />
                      )}
                    </>
                  ) : (
                    <Text
                      className="text-footer"
                      styles={() => ({ root: { fontSize: 12 } })}
                    >
                      Select country
                    </Text>
                  )}
                  <ArrowDownOutline
                    className={classNames(
                      countryClasses.icon,
                      "w-4 h-4 text-neutral-40",
                    )}
                  />
                </UnstyledButton>
              </Menu.Target>
              <Menu.Dropdown style={{ maxHeight: "300px", overflow: "auto" }}>
                {countryItems}
              </Menu.Dropdown>
            </Menu>
          </Box>
          <Box className="w-full">
            <Menu
              onOpen={() => setProviderOpened(true)}
              onClose={() => setProviderOpened(false)}
              radius="md"
              withinPortal
            >
              <Menu.Target>
                <UnstyledButton
                  className={`${providerClasses.control} space-x-4`}
                >
                  {providerOpened ? (
                    <InputField
                      onClick={(e) => e.stopPropagation()}
                      classNames={{
                        input:
                          "rounded-none !pl-5 !border-0 focus:shadow-none !outline-none focus:border-0 focus-within:border-0 focus-within:border-transparent focus:border-transparent focus:appearance-none  h-[38px] min-h-[38px]",
                      }}
                      value={searchProviderQuery}
                      iconWidth={12}
                      size="xs"
                      onChange={(e) => setSearchProviderQuery(e.target.value)}
                      icon={<SearchNormal1Outline className="w-3 h-3" />}
                    />
                  ) : providerFilter ? (
                    <>
                      <Group spacing="xs" className="w-full">
                        {providerFilter?.provider?.icon_url && (
                          <Avatar
                            size={24}
                            src={providerFilter?.provider?.icon_url}
                          />
                        )}
                        <span className={`${providerClasses.label}`}>
                          {providerFilter?.name}
                        </span>
                      </Group>
                      {providerFilter && (
                        <CloseCircleFill
                          className="w-4 h-4 text-neutral-50 mr-2"
                          onClick={() => {
                            setProviderFilter(null);
                          }}
                        />
                      )}
                    </>
                  ) : (
                    <Text
                      className="text-footer"
                      styles={() => ({ root: { fontSize: 12 } })}
                    >
                      Select provider
                    </Text>
                  )}
                  <ArrowDownOutline
                    className={classNames(
                      providerClasses.icon,
                      "w-4 h-4 text-neutral-40",
                    )}
                  />
                </UnstyledButton>
              </Menu.Target>
              <Menu.Dropdown style={{ maxHeight: "300px", overflow: "auto" }}>
                {providerItems}
              </Menu.Dropdown>
            </Menu>
          </Box>
          <InputField
            radius={12}
            placeholder="Price"
            size="xs"
            classNames={{
              input: "rounded-xl",
              label: "mb-3 text-p-01-semibold text-dark",
            }}
            className="w-full mb-4"
            {...addPricingFormData.getInputProps("price")}
          />

          <Box className="flex flex-col">
            <Text className="text-center text-neutral-30 text-p-01 mb-4 p-4">
              Please enter the confirmation code you see on the authentication
              application
            </Text>
            <Box className="flex justify-center items-center">
              <OTPInput
                radius={12}
                size="md"
                required
                inputClassName=""
                onChange={(res) => {
                  addPricingFormData.setFieldValue("otp_code", res);
                }}
                allowedCharacters="numeric"
              />
            </Box>
          </Box>
        </Box>
        <Button
          className="mt-8"
          fullWidth
          type="submit"
          disabled={!addPricingFormData.isDirty()}
          size="xs"
        >
          Add pricing
        </Button>
      </Box>
    </Box>
  );
}
